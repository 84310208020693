var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Page Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Page Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Page Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Page Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CIT Milestone Section Title","label-for":"cit_milestone_title"}},[_c('validation-provider',{attrs:{"name":"cit_milestone_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_milestone_title","state":errors.length > 0 ? false : null,"name":"cit_milestone_title","placeholder":"Enter Your CIT Milestone Section Title"},model:{value:(_vm.form.cit_milestone_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_milestone_title", $$v)},expression:"form.cit_milestone_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CIT Milestone Section Description","label-for":"cit_milestone_description"}},[_c('validation-provider',{attrs:{"name":"cit_milestone_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cit_milestone_description","state":errors.length > 0 ? false : null,"name":"cit_milestone_description","placeholder":"Enter Your CIT Milestone Section Description","rows":"4"},model:{value:(_vm.form.cit_milestone_description),callback:function ($$v) {_vm.$set(_vm.form, "cit_milestone_description", $$v)},expression:"form.cit_milestone_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CIT Initiatives Section Title","label-for":"cit_initiatives_title"}},[_c('validation-provider',{attrs:{"name":"cit_initiatives_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_initiatives_title","state":errors.length > 0 ? false : null,"name":"cit_initiatives_title","placeholder":"Enter Your CIT Initiatives Section Title"},model:{value:(_vm.form.cit_initiatives_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_initiatives_title", $$v)},expression:"form.cit_initiatives_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CIT Initiatives Section Description","label-for":"cit_initiatives_description"}},[_c('validation-provider',{attrs:{"name":"cit_initiatives_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cit_initiatives_description","state":errors.length > 0 ? false : null,"name":"cit_initiatives_description","placeholder":"Enter Your CIT Initiatives Section Description","rows":"4"},model:{value:(_vm.form.cit_initiatives_description),callback:function ($$v) {_vm.$set(_vm.form, "cit_initiatives_description", $$v)},expression:"form.cit_initiatives_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CIT Branch Section Title","label-for":"cit_branch_title"}},[_c('validation-provider',{attrs:{"name":"cit_branch_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_branch_title","state":errors.length > 0 ? false : null,"name":"cit_branch_title","placeholder":"Enter Your CIT Branch Section Title"},model:{value:(_vm.form.cit_branch_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_branch_title", $$v)},expression:"form.cit_branch_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CIT Branch Section Description","label-for":"cit_branch_description"}},[_c('validation-provider',{attrs:{"name":"cit_branch_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cit_branch_description","state":errors.length > 0 ? false : null,"name":"cit_branch_description","placeholder":"Enter Your CIT Branch Section Description","rows":"4"},model:{value:(_vm.form.cit_branch_description),callback:function ($$v) {_vm.$set(_vm.form, "cit_branch_description", $$v)},expression:"form.cit_branch_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"CIT Department Section Title","label-for":"cit_department_title"}},[_c('validation-provider',{attrs:{"name":"cit_department_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cit_department_title","state":errors.length > 0 ? false : null,"name":"cit_department_title","placeholder":"Enter Your CIT Department Section Title"},model:{value:(_vm.form.cit_department_title),callback:function ($$v) {_vm.$set(_vm.form, "cit_department_title", $$v)},expression:"form.cit_department_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"CIT Department Section Description","label-for":"cit_department_description"}},[_c('validation-provider',{attrs:{"name":"cit_department_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"cit_department_description","state":errors.length > 0 ? false : null,"name":"cit_department_description","placeholder":"Enter Your CIT Department Section Description","rows":"4"},model:{value:(_vm.form.cit_department_description),callback:function ($$v) {_vm.$set(_vm.form, "cit_department_description", $$v)},expression:"form.cit_department_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Meta Title","label-for":"meta_title"}},[_c('validation-provider',{attrs:{"name":"meta_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"meta_title","state":errors.length > 0 ? false : null,"name":"meta_title","placeholder":"Enter Your Meta Title"},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Meta Description","label-for":"meta_description"}},[_c('validation-provider',{attrs:{"name":"meta_description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"meta_description","state":errors.length > 0 ? false : null,"name":"meta_description","placeholder":"Enter Your Meta Description","rows":"4"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }