<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-6">
            <b-form-group label="Page Title" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="errors.length > 0 ? false : null"
                  name="title"
                  placeholder="Enter Your Page Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Page Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  placeholder="Enter Your Page Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="CIT Milestone Section Title"
              label-for="cit_milestone_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_milestone_title"
                rules="required"
              >
                <b-form-input
                  id="cit_milestone_title"
                  v-model="form.cit_milestone_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_milestone_title"
                  placeholder="Enter Your CIT Milestone Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CIT Milestone Section Description"
              label-for="cit_milestone_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_milestone_description"
                rules="required"
              >
                <b-form-textarea
                  id="cit_milestone_description"
                  v-model="form.cit_milestone_description"
                  :state="errors.length > 0 ? false : null"
                  name="cit_milestone_description"
                  placeholder="Enter Your CIT Milestone Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="CIT Initiatives Section Title"
              label-for="cit_initiatives_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_initiatives_title"
                rules="required"
              >
                <b-form-input
                  id="cit_initiatives_title"
                  v-model="form.cit_initiatives_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_initiatives_title"
                  placeholder="Enter Your CIT Initiatives Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CIT Initiatives Section Description"
              label-for="cit_initiatives_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_initiatives_description"
              >
                <b-form-textarea
                  id="cit_initiatives_description"
                  v-model="form.cit_initiatives_description"
                  :state="errors.length > 0 ? false : null"
                  name="cit_initiatives_description"
                  placeholder="Enter Your CIT Initiatives Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="CIT Branch Section Title"
              label-for="cit_branch_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_branch_title"
                rules="required"
              >
                <b-form-input
                  id="cit_branch_title"
                  v-model="form.cit_branch_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_branch_title"
                  placeholder="Enter Your CIT Branch Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CIT Branch Section Description"
              label-for="cit_branch_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_branch_description"
              >
                <b-form-textarea
                  id="cit_branch_description"
                  v-model="form.cit_branch_description"
                  :state="errors.length > 0 ? false : null"
                  name="cit_branch_description"
                  placeholder="Enter Your CIT Branch Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="CIT Department Section Title"
              label-for="cit_department_title"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_department_title"
                rules="required"
              >
                <b-form-input
                  id="cit_department_title"
                  v-model="form.cit_department_title"
                  :state="errors.length > 0 ? false : null"
                  name="cit_department_title"
                  placeholder="Enter Your CIT Department Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="CIT Department Section Description"
              label-for="cit_department_description"
            >
              <validation-provider
                #default="{ errors }"
                name="cit_department_description"
              >
                <b-form-textarea
                  id="cit_department_description"
                  v-model="form.cit_department_description"
                  :state="errors.length > 0 ? false : null"
                  name="cit_department_description"
                  placeholder="Enter Your CIT Department Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  data() {
    return {
      form: {
        title: "",
        description: "",
        cit_milestone_title: "",
        cit_milestone_description: "",
        cit_initiatives_title: "",
        cit_initiatives_description: "",
        cit_branch_title: "",
        cit_branch_description: "",
        cit_department_title: "",
        cit_department_description: "",
        meta_title: "",
        meta_description: "",
      },
      required,
      email,
    };
  },
  mounted() {
    this.getAboutUs();
  },
  methods: {
    async getAboutUs() {
      const about_us = await this.callApi("get", "/app/about/us/show");
      if (about_us.status == 200) {
        this.form.title = about_us.data.about_us.title;
        this.form.description = about_us.data.about_us.description;
        this.form.cit_milestone_title =
          about_us.data.about_us.cit_milestone_title;
        this.form.cit_milestone_description =
          about_us.data.about_us.cit_milestone_description;
        this.form.cit_initiatives_title =
          about_us.data.about_us.cit_initiatives_title;
        this.form.cit_initiatives_description =
          about_us.data.about_us.cit_initiatives_description;
        this.form.cit_branch_title = about_us.data.about_us.cit_branch_title;
        this.form.cit_branch_description =
          about_us.data.about_us.cit_branch_description;
        this.form.cit_department_title =
          about_us.data.about_us.cit_department_title;
        this.form.cit_department_description =
          about_us.data.about_us.cit_department_description;
        this.form.meta_title = about_us.data.about_us.meta_title;
        this.form.meta_description = about_us.data.about_us.meta_description;
      }
    },
    Update() {
      axios
        .post("/app/about/us/update", this.form)
        .then((res) => {
          this.getAboutUs();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.cit_milestone_title)
              this.e(e.response.data.errors.cit_milestone_title[0]);
            if (e.response.data.errors.cit_milestone_description)
              this.e(e.response.data.errors.cit_milestone_description[0]);
            if (e.response.data.errors.cit_initiatives_title)
              this.e(e.response.data.errors.cit_initiatives_title[0]);
            if (e.response.data.errors.cit_initiatives_description)
              this.e(e.response.data.errors.cit_initiatives_description[0]);
            if (e.response.data.errors.cit_branch_title)
              this.e(e.response.data.errors.cit_branch_title[0]);
            if (e.response.data.errors.cit_branch_description)
              this.e(e.response.data.errors.cit_branch_description[0]);
            if (e.response.data.errors.cit_department_title)
              this.e(e.response.data.errors.cit_department_title[0]);
            if (e.response.data.errors.cit_department_description)
              this.e(e.response.data.errors.cit_department_description[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  computed: {},
  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
