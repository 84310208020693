<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-2" justified>
        <b-tab active title="Page">
          <b-card-text>
            <Page />
          </b-card-text>
        </b-tab>
        <b-tab title="ISO Certified">
          <b-card-text>
            <ISOCertified />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import Page from "./partials/Page.vue";
import ISOCertified from "./partials/ISOCertified.vue";
export default {
  components: {
    Page,
    ISOCertified,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
